.support-list-content {
    max-height: 30px;
    overflow: hidden;
}

.support-list-link {
    color: $header-link;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.support-ticket {
    &-question {
        border: 2px solid $grey-light;
        padding: 10px;
    }

    &-form {
        &-group {
            display: flex;
            padding: 5px 0;
            label {
                width: 20%;
                float: left;
            }

            input {
                width: 80%;
                float: right;
                border-radius: 5px;
                border: 1px solid $grey-light;
            }
        }

        textarea {
            margin-top: 10px;
            border-radius: 5px;
            border: 1px solid $grey-light;
            width: 99%;
            height: 120px;
            max-height: 500px;
        }
    }

    &-buttons {
        input, button {
            margin: 10px 5px;
        }
    }
}
