.Form {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    padding: 30px 15px;
    text-align: center;

    input {
        min-width: 300px;
        border-radius: 5px;
        margin: 5px 0;
        font-size: 18px;
        height: 36px;
        display: flex;
        border: 0;
        color: #3d3d3d;
    }

    button {
        margin-top: 20px;
        height: 36px !important;
        font-size: 18px;
    }
}
