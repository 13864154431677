@import "../../styles/variables";

.Title {
    color: $green-dark;
}

.FilterArea {
    display: flex;
    height: 50px;
    align-items: center;

    .Tabs {
        flex: 1;

        button {
            box-sizing: border-box;
            color: #0b0b0b;
            background: none;
            border: 0;
            border-right: 1px solid $grey-light;
            padding: 5px 13px;
            text-transform: capitalize;

            &:hover {
                text-decoration: underline;
            }

            &.Selected {
                font-weight: bold;
                color: $green-main;
                border: 1px solid $green-dark;
            }
        }
    }

    .SearchInput {
        padding: 6px 20px;
        border: 1px solid $background-color;
        border-radius: 5px;
        color: #9a9b9a;

        &::placeholder {
            opacity: 0.3;
        }

        &:focus {
            outline: none;
            border: 1px solid $grey-light;
        }
    }
}


.Item {
    &.Unread {
        background: $converstaionsUnread !important;
    }

    &.FromTravelApp {
        background: #9cacfb;
    }

    &.FromTravelfriend {
        background: #d8e9f5;
    }

    &.ItemDeleted {
        padding: 5px;
        background: rgba(245, 140, 140, 0.55);
        justify-content: space-between;
    }

    &:first-of-type {
        border-top: 1px solid #9a9b9a;
    }

    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 45px;
    border-bottom: 1px solid #9a9b9a;
    width: 100%;

    .buttonUser {
        &:hover {
            background: $conversationsHover;
        }
        width: 210px;
        height: 100%;
        border: 0;
        padding: 0;
        display: flex;
        background: none;
        align-items: center;

        .RepliedArea {
            width: 20px;
            color: #9a9b9a;
        }
        .PhotoArea {
            width: 40px;

            img {
                width: 30px;
                height: 100%;
            }
        }
        .UserInfoArea {
            margin-left: 5px;
            color: $green-dark;

            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            .Online {
                font-size: 9px;
            }

            .Offline {
                color: $grey-light;
            }

            .Name {
                padding: 0 4px;
            }

            .Status {
                font-size: 10px;
                color: #9a9b9a;
            }
        }
    }

    .ContentArea {
        padding: 0 5px;
        box-sizing: border-box;
        line-height: 45px;
        height: 100%;
        flex: 1;

        &:hover {
            background: $conversationsHover;
        }


        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;


        background: none;
        border: none;
        color : #333333;


    }

    .TimeAgoArea {
        width: 105px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        color: #a3a3a3;
    }

    .DeleteArea {
        button {
            font-size: 16px;
            padding: 10px;
            border: 0;
            background: none;
            color: $converstaionsTrashed;

            &:hover {
                color: $grey-dark;
            }
        }

        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 40px;
    }
}


.LoadMoreArea {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
}


.EmptyList {
    padding: 40px 0;
    font-size: 16px;
    text-align: center;
}
