@mixin square($edge) {
    width: $edge;
    height: $edge;
}

@mixin circle($diameter) {
    @include square($diameter);

    border-radius: 50%;
}

@mixin breakpoint($point) {
    @if $point == sm_mobile{
        @media (max-width: 480px) {
            @content;
        }
    }
    @if $point == mobile {
        @media (max-width: 739px) {
            @content;
        }
    }

    @if $point == tablet {
        @media (min-width: 740px) {
            @content;
        }
    }
    @else if $point == tablet-horizontal {
        @media (min-width: 1024px) {
            @content;
        }
    }
    @else if $point == desktop {
        @media (min-width: 1200px) {
            @content;
        }
    }
}
