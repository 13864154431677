.faq-list {
    td {
        font-size: 12px;
        border: none;
        border-bottom: 1px solid $grey-light;

        i {
            font-size: 14px;
            font-weight: 700;
            cursor: pointer;
            color: $green-main;
        }
    }
}

.faq-form {
    &-group {
        display: block;

        label {
            font-size: 12px;
            padding: 5px 2px;
            display: block;
        }

        input {
            display: inline-block;
        }

        &-input {
            border-radius: 5px;
            border: 1px solid $grey-light;
            height: 25px;
            width: 60%;
            padding: 2px;
        }

        textarea {
            height: 100px;
        }
    }

    a.green-button {
        font-size: 10px;
        text-decoration: none;
        line-height: 30px;
        vertical-align: middle;
        text-align: center;
    }
}
