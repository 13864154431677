@import "src/styles/variables";

.ButtonLink {
    background: none;
    border: none;
    color: $green-lighter;

    &:hover {
        text-decoration: underline;
        color: $green-dark;
    }
}
