.dimensionsTable {
    margin-bottom: 10px;
}

.dimensionsTable td{
    border: none;
    padding: 6px 6px;
    border-bottom: 1px solid #ccc;
}

.dimensionsTable th{
    padding-top: 5px;
    padding-bottom: 5px;
}

.filters button {
    margin-left: 5px;
}

.statisticsTable th{
    padding-left: 3px;
    padding-right: 3px;
}