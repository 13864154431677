html, body {
  margin: 0;
  padding: 0;
}

body {
  border-top: 3px solid $green-main;
  //background: repeating-linear-gradient(90deg, $background-color, $background-color 5px, $background-line 5px, $background-line 7px);
  background: $background-color;
  font-family: Verdana, Arial, sans-serif;
  font-size: 11px;
  color: $header-link;
}

.page-width {
  width: $layout-width;
  margin: 0 auto;
}

.wrapper {
  display: flex;
  margin: 0 auto;
}

.content {
  width: $content-width;
  padding: 10px;
  border: 1px solid #CCC;
  margin: 5px;
  background: white;
  border-radius: 5px;
}

.content-fullWidth {
  width: $layout-width;
  padding: 10px;
  border: 1px solid #CCC;
  margin: 5px;
  background: white;
  border-radius: 5px;
  overflow-x: scroll;
}
