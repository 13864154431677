.Wrapper {
    position: relative;
    width: 450px;
    height: 550px;
}

.Modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 40px;
}

.ButtonsContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.ZoomRangeContainer {
    width: 50%;
    height: 30px;
    display: flex;
    align-items: center;

    &:hover {
        input[type='range']::-webkit-slider-thumb {
            box-shadow: 0 0 0 8px rgba(63, 81, 181, 0.16);
            border-radius: 50%;
        }

        input[type='range']::-moz-range-thumb {
            box-shadow: 0 0 0 8px rgba(63, 81, 181, 0.16);
        }
    }

    .ZoomRange {
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 1px;
        background: #3f51b5;
        width: 100%;

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            -moz-appearance: none;
            border: 1px solid #3f51b5;
            background: #3f51b5;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }

        &::-moz-range-thumb {
            -webkit-appearance: none;
            -moz-appearance: none;
            border: 1px solid #3f51b5;
            background: #3f51b5;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
    }
}
