.SenderInfo {
    padding: 10px;
}

.SenderAllInfo {
    background: #99CCFF;
    padding: 10px 0px 0px 5px;
}

.Line {
    display: flex;
    padding-bottom: 5px;

    .Label {
        width: 120px;
        padding: 5px 0 4px;
    }

    .InputsWide {
        width: 250px;
    }

    .ContentInput {
        width:300px; 
        height:100px
    }

    select {
        margin-right: 5px;
    }
}

.Content {
    border: 1px solid grey;
    display: block;
}

.SmallLoader {
    width: 7px;
    height: 7px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #1C7D04;
    margin: 2px auto;
}

.Hidden {
    display: none;
}

.LineCentered {
    align-items: center;
}