.latest-verified {
    display: inline-block;
    margin-left: 5px;

    img {
        border: 1px solid #999;
        width: 50px;
    }
}

.pending-verification {
    td {
        border: none;
        vertical-align: top;
        border-bottom: 2px solid #999;
    }

    &-photo {
        width: 105px !important;
        text-align: center;

        a, span {
            font-size: 10px;
        }

        img {
            width: 100px;
            height: 120px;
            object-fit: contain;
        }

        .rotate-icon {
            font-size: 14px;
            margin: 1px 5px;
        }
    }

    .block-reason {
        font-size: 14px;
        font-weight: 700;
    }

    &-count {
        color: red;
        font-weight: 700;
    }

    &-check {
        width: 60px !important;
        color: $green-lighter;
        font-size: 40px;
        text-align: center;
        clear: both;
        border: 1px solid grey;
        border-radius: 5px;
    }

    &-decline {
        width: 60px !important;
        color: $red-main;
        font-size: 40px;
        text-align: center;
        margin-top: 20px;
        border: 1px solid grey;
        border-radius: 5px;
    }

    &-duplicate {
        padding-left: 2px;
    }

    &-reason {
        width: 210px;
    }

    i {
        cursor: pointer;
    }
}
.pending-verification:nth-child(even) {
    background-color: lightyellow;
}
.popup-overlay {
    z-index: 1001 !important;
}
.popup-content {
    width: unset !important;
    padding: 40px 20px 0 !important;
}
.crop-wrapper {
    position: relative;
    width: 450px;
    height: 550px;
  }
  
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
  }
  
  .crop-button {
    position: absolute;
    cursor: pointer;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .close-button {
    position: absolute;
    cursor: pointer;
    top: -40px;
    font-size: 26px;
    right: -10px;
  }

.old-verifications {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.old-verification-item {
    width: 250px;
    height: 315px;
    margin: 40px 25px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: relative;

    .inactive{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #333;
        opacity: 0.7;
        color: #fff;
        font-size: 30px;
        text-align: center;
    }

    img {
        width: 250px;
        object-fit: contain;
        height: 270px;
    }
    .delete-button {
        background-color: #FF0000;
        padding: 10px 20px;
        font-weight: bold;
        color: #fff;
        width: 130px;
        text-align: center;
        margin: 0 auto;
        cursor: pointer;
    }
}

.load-more {
    margin: 0 auto;
    display: block;
    width: 200px;
}
  

