.Indicator {
    display: flex;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    background: #9a9b9a;
    margin: 0 3px;
}

.Online {
    background: darkseagreen !important;
}
