button {
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
}

.green-button {
    background-image: linear-gradient($green-main, $green-lighter, $green-dark);
    border: 2px solid #369004;
    height: 30px;
    min-width: 100px;
    color: #fff;
    font-weight: 700;
    border-radius: 7px;
}

.green-button-simple {
    padding: 5px;
    border-radius: 5px;
    border: none;
    color: #fff;
    background: #1C7D04;
    margin: 5px 0;
    min-width: 80px;
    &:hover {
        cursor: pointer;
    }
}

button:disabled,
button[disabled]{
  background-color: #cccccc;
  color: #666666;
}

.red-button {
    padding: 5px;
    border-radius: 5px;
    border: none;
    color: #fff;
    background: #ff1100;
    margin: 5px 0;
    min-width: 80px;
    &:hover {
        cursor: pointer;
    }
}

.danger-button {
    background: $red-main;
    height: 30px;
    min-width: 80px;
    color: #fff;
    font-weight: 700;
    border-radius: 7px;
}

.grey-button {
    background: $grey-light;
    height: 30px;
    min-width: 100px;
    font-weight: 700;
    border-radius: 7px;
    color: $grey-dark;
}

.loader-helper {
    display: inline-block;
    width: 100%;
}

.loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid $green-main; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
    margin: 10px auto 0;
}

h1 {
    font-size: 16px;
}

h3 {
    font-size: 12px;
}

textarea {
    font-family: Arial, sans-serif;
    font-size: 10pt;
}

.table-links {
    cursor: pointer;
    color: $green-main;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.table-photo {
    width: 50px;
    height: auto;
}

.green-text {
    color: $green-main;
}

.grey-text {
    color: $grey-light;
}

.dark-grey-text {
    color: #757575;
}

.red {
    color: red;
}

.purple {
    color:purple;
}

.green  {
    color: $green-main;
}

.red-text {
    color: red;
    text-decoration: none;
    padding: 0 1px;
    &:hover {
        text-decoration: underline;
    }
}

.yellow-back {
    background-color: yellow;
    font-weight: bolder;
}

.tac {text-align: center;}
.fl {float: left;}
.fr {float: right;}
.ml-5 {margin-left: 5px;}
.mt-10 {margin-top: 10px;}
.mt-15 {margin-top: 15px;}
.mt-20 {margin-top: 20px;}
.mt-50 {margin-top: 50px;}
.d-block {display: block;}
.tf {color: $tf-blue;}
.green-bg {background: #1C7D04;}
.hidden {display: none}
.inline {display: inline-block;}

.big-button {
    width: auto;
    height: 40px;
    font-size: 14px;
}

.button {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid $grey-light;
    margin: 5px 0;
    width: 98%;
    &:hover {
        cursor: pointer;
    }
}

.pointer {
    cursor: pointer;
}

.capitalize {
    text-transform: capitalize;
}

.no-results {
    display: inline-block;
    width: 100%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
