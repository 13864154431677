.video-verification {
    td {
        border: none;
        vertical-align: top;
        border-bottom: 1px solid $grey-light;
    }

    &-photo {
        width: 105px !important;

        img {
            width: 150px;
            height: 200px;
        }
    }

    &-video {
        width: 300px !important;
    }

    &-actions {
        vertical-align: middle !important;
    }
}
