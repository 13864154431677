.error-logs_header {
    display: flex;
    align-items: center;
    width: 100%;
}

.error-logs-form {
    input {
        font-size: 12px;
        height: 15px;
        padding: 2px;
        display: inline-block;
        vertical-align:top;
        border: 1px solid #ccc;
        margin: 0px 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
    }

    .multi-select {
        width: 160px;
    }

    input[type="checkbox"] {
        display: inline-block;
        vertical-align:top;
    }

    &-checkbox-label {
        vertical-align: top;
    }

    button {
        margin-left: 20px;
        height: 25px;
    }

    &-error-type {
        width: 30%;
        max-width: 350px;
        font-size: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-error-field {
        width: 10%;
        max-width: 10% !important;
        font-size: 10px;
    }

    &-error-unseen {
        width: 0.1%;
        background-color: $green-main !important;
    }

    &-error-error {
        background-color: rgba(210,34,45, 0.85);
        color: #fff;
    }

    &-error-404 {
        background-color: rgba(173, 216, 230, 1);
    }

    &-error-seen {
        width: 0.1%;
    }

    .load-more {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
        height: 30px;
    }
}
