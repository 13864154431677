.header {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  margin-top: -3px;

  &-logo {
      width: 200px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
          width: 96px;
          height: auto;
      }
  }

  &-menu {
    float: left;
    margin-top: 12px;
    padding: 47px 10px 5px 35px;

    a {
      color: $header-link;
      font-weight: bold;
      padding: 0px 4px 2px 5px;
      text-decoration: none;
      margin-right: 10px;
      text-transform: uppercase;

      &:hover {
        border-bottom: 3px solid $header-link-hover;
      }
    }
  }

  &-user-info {
    position: absolute;
    bottom: 18px;
    right: 25px;

      text-align: right;

    &-logout-link {
      color: $header-link;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &-sep {
      color: $header-seperator;
    }

    &-name {
      color: $header-administration-purple;
      font-weight: bold;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &-conversations-bubble {
      position: absolute;
      right: 0;
      bottom: 39px;
      background-color: $header-administration-purple;
      border-radius: 8px;
      padding: 2px 0px 3px 0px;
      width: 24px;
      text-align: center;
      color: #fff;
      font-weight: bold;
      text-decoration: none;
    }
  }

  &-online-users-count {
    position: absolute;
    top: 15px;
    left: 460px;
    color: grey;
  }

  &-administration-box {
    z-index: 1000;
    position: absolute;
    top: 10px;
    left: 615px;
    width: 170px;

    a {
      opacity: 0.55;
      -moz-opacity: 0.55;
      filter: alpha(opacity=55);
      font-weight: bold;
      color: $header-administration-color;
      text-decoration: none;

      &:hover {
          text-decoration: underline;
      }
    }
  }
}
