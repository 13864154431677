.Container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.ContainerWithOverflow {
    height: 18px;
    overflow-y: hidden;
}

.Item {
    background: #ECECEC;
    display: flex;
    padding: 1px 6px;
    margin: 1px 1px;
    border-radius: 4px;
}

.ButtonShowMore {
    background: none;
    border: none;
    color: #1a6e03;
    text-decoration: underline;
}
