.MessageColumn {
    width: 60%;
    position: relative;

    &:hover {
        cursor: pointer;

        .Content {
            text-decoration: underline;
            color: #000;
        }
    }


    .Content {
        position: absolute;
        left: 5px;
        top: 15px;
        bottom: 5px;
        right: 15px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        color: #1b323b;
    }

    .Time {
        position: absolute;
        right: 15px;
        top: 0;
        color: darkgrey
    }
}
