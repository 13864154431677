.LoaderOpacity {
    background: #000;
    opacity: 0.8;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
}

.LoginAsName {
    max-width: 110px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
}

.PrivilegesModal, .SettingsModal {
    padding: 20px;
    font-size: 14px;
    min-width: 300px;

    .ActionsArea {
        display: flex;
        flex-direction: column;
    }

    .ButtonLink {
        text-transform: capitalize;
        background: none;
        border: none;
        text-align: left;

        &:hover {
            text-decoration: underline;
        }
    }
}
