.user-search {
    &-form {
        background: #CCCCFF;
        padding: 10px;
    }

    mark {
        color: red;
        font-weight: 700;
    }

    td.male {
        color: blue;
    }

    td.female, td.blocked, td.hidden {
        color: red;
    }

    &-link {
        color: $green-main;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }

    &-photo {
        width: 50px;
        height: auto;
    }

}
