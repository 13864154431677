.reports-area {
    h1 {
        span {
            text-transform: capitalize;
        }
    }
    .add-to-unwanted {
        position: absolute;
        margin-top: -45px;
        margin-left: 210px;
        width: 100px;
        border: 1px solid #ddd;
        border-radius: 3px;
        padding: 3px;
        background-color: #ffffff;
    }

    .report-table {
        td {
            padding: 6px 4px;
        }
    }

    .add-to-displayed-prices-action {
        position: absolute;
        margin-top: -20px;
        margin-left: 210px;
        width: 100px;
        padding: 3px;
        color: purple;
    }

    .warning-about-escort {
        color: purple;
        margin: 0 5px;
        font-weight: bold;
    }

    .image-wrapper {
        position: relative;
    }

    .users-reports-title {
        background-color: #888;
        color: #fff;
        padding-left: 20px !important;
        font-size: 12px;
    }

    .report-count {
        vertical-align: top;
        margin-left: 3px;
        color: red;
        cursor: pointer;
    }

    .premium {
        color: #eae508;
        font-size: 15px;
        position: absolute;
        left: 40px;
        bottom: -2px;
        filter: drop-shadow(0px 0px 1px rgb(0 0 0 / 0.5));
    }

    .user-comment {
        margin-bottom: 5px;
        width: 160px;
    }

    .user-male {
        color: rgb(0, 30, 202);
    }

    .user-female {
        color: rgb(201, 0, 124);
    }

    .blocked-border {
        border: 1px solid red;
    }

    .filters {
        margin: 12px 10px;
        float: left;

        input, select, button {
            margin-left: 2px;
            margin-right: 2px;
        }
    }

    .popup-view {
        width: 300px;
        padding: 12px 30px;
        font-size: 14px;
    }

    .ignore-user {
        margin-top: 8px;
        text-decoration: underline;
    }

    .ceiling {
        float: right;
        text-decoration: overline;
    }

    .popup-line {
        margin-bottom: 5px;

        textarea {
            width: 280px;
            height: 60px;
        }
    }

    .score-label {
        float: left;
        margin-top: 2px;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .load-more {
        width: 100%;
    }

    &-attachments {
        display: inline;
        border: 1px solid #b2b2b2;
        height: 30px;
        width: 30px;
        margin-left: 2px;
    }
}
