.Filter {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;

    select {
        height: 25px;
    }

    .AreaBlocked {
        display: flex;
        align-items: center;
    }
}

.Table {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 0 10px;
    align-items: center;
}

.Row {
    padding: 10px;
    border-bottom: 1px solid #CDCDCD;
    min-height: 100px;
}

.Head {
    background: #f0f1e9;
    border-radius: 5px;
    font-weight: 600;
    align-items: center;
    min-height: 40px;
}

.CellUnread {
    width: 20px;
    color: #993399;
}

.CellProfile {
    width: 120px;
}

.CellAgeFrom {
    width: 50px;
}

.CellAgeTo {
    width: 50px;
}

.CellCountries {
    width: 110px;
    padding: 0 3px;
}

.CellContent {
    width: 250px;
}

.CellStatus {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-end;
}

.Button {
    min-width: 35px;
    text-align: center;
}

.User {
    box-sizing: border-box;
    padding: 1px;

    img {
        height: 60px;
        padding: 1px;
        border: 1px solid #cacaca;
        border-radius: 3px;
    }

    display: flex;
    height: 60px;
    width: 100%;
    align-items: center;

    .Blocked {
        padding: 0 3px;
        color: indianred;
        font-weight: 600;
    }
}

.MultipleSelectox {
    width: 100%;
}

