@import "src/styles/variables";

.PageBackground {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.Header {
    display: flex;
    position: relative;
    align-items: center;
    .ButtonBack {
        padding: 3px 15px;
        margin-left: auto;
        cursor: pointer;
    }
    z-index: 1;
}

.ChatArea {
    position: relative;
    background: #f8f9f7;
    border: 1px solid #b2b2b2;
    border-radius: 4px;
    margin-left: 150px;
    padding: 10px;

    .Textarea textarea {
        font-size: 12px;
        font-style: inherit;
    }

    .Actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 46px;
        padding: 0 10px;
    }
}

.ActionAttach {
    color : #666;
    i {
        font-size: 21px;
    }
    .UploadAttForm {
        position: relative;

        .AttachmentLoader {
            display: inline-block;
        }
    }
    .FileInput {
        opacity: 0;
        cursor: pointer;
        position: absolute;
        width: 22px;
        height: 22px;
        left: 0;
    }
}

.MessageList {
    .DeletingOverflow {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #000;
        opacity: 0.3;

    }
    .Item {
        &.Mine {
            .MessageArea {
                background: #f8f9f7 !important;
            }
        }

        position: relative;
        display: flex;
        margin-top: 10px;
        box-sizing: border-box;

        .UserArea {
            width: 150px;
            display: flex;
            padding-top: 5px;

            .Link {
                display: flex;
                width: 100%;
            }

            .UserImage {
                width: 50px;
                height: 50px;
                padding: 1px;
                border: 1px solid #999;
            }

            .UsertextArea {
                display: flex;
                align-items: center;
                margin: 0 0 2px 5px;
            }

            .Blocked {
                color: indianred;;
            }

            .Hidden {
                color : #666;
            }

            .Verified {
                color : #1a6e03;
            }
        }

        .MessageArea {
            position: relative;
            width: 100%;
            flex: 1;
            background: #f2ffdb;
            border: 1px solid #b2b2b2;
            border-radius: 4px;
            padding: 20px 20px 30px 20px;
            font-size: 12px;
            line-height: 1.5;

            .TimeArea {
                font-size: 10px;
                color: #999;

                position: absolute;
                right : 20px;
                top: 4px;
            }

            .MoreInfoButton {
                position: absolute;
                left: 20px;
                bottom: 4px;
                font-size: 14px;
                color: #1a6e03;
                cursor: pointer;
            }

            .DeleteButton {
                position: absolute;
                right: 10px;
                bottom: 4px;
                font-size: 16px;
            }
        }
    }
}

.LoadMoreArea {
    position: relative;
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
}

.HelpersWrapper {
    position: absolute;
    top: 14px;
    right: 0;
    padding: 7px;

    background: #FFF;
    border: 1px solid #7d7a7a;
    border-radius: 5px;
    z-index: 2;

    display: grid;
    grid-template-columns: 120px 120px;
}

.MessageInfo {
    background: #af9985;
    padding: 10px 4px;
    border-radius: 4px;
    margin: 10px 0;
    display: grid;
    grid-template-columns: 60px auto;
}
