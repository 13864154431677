table {
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid $background-color;
  padding: 8px;
}

//tr:nth-child(even){background-color: $background-line;}

//tr:hover {background-color: #ddd;}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: $green-main;
  color: white;
}

.male {
    color: blue;
}

.female, td.blocked, td.hidden {
    color: red;
}
