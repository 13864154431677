@import "./src/styles/variables";

.Button {
    background: $green-main;
    color: #FFF;
    border: 0;
    height: 24px;
    padding: 0 15px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
        background: $green-dark;
        text-decoration: underline;
    }
}
