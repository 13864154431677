@import "src/styles/variables";

.Favorites {
    background: none;
    border: none;
    width: 30px;
    height: 30px;
    font-size: 18px;
    color : $grey-light;
}
