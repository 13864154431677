.Loader {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    &.White {
        svg path, svg rect{
            fill: #FFF !important;
        }
    }

    svg path, svg rect{
        fill: #61A51DFF;
    }
}

