@import "src/stylesNew/main";

.Modal {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: rgba(black, 0.7);
}

.ModalWrapper {
    box-sizing: border-box;
    background-color: transparent;
    height: 100%;
    text-align: center;
    font-size: 0;
    padding: 10px;

    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
}

.ModalWindow {
    display: inline-block;
    position: relative;
    margin: 20px auto;
    padding-bottom: 20px;
    font-size: 1rem;
    vertical-align: middle;
    text-align: left;
}

.ModalContainer {
    max-width: 600px;
    background-color: white;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    padding: 10px;
}

.ModalContainerNoPadding {
    padding: 0 !important;
}

.ModalClose {
    //@include circle(32px);
    position: absolute;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    //background: $modalGrey;
    //border: 2px solid white;
    top: -16px;
    right: 0;
    color: #FFF;
    font-size: 12px;
    //box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.05);

    &:hover {
        //background: darken($modalGrey, 10%);
    }
}

@include breakpoint(mobile) {
    .ModalWrapper {
        padding: 25px;
    }
}
