.InfoTable {
    border: 0;

    td {
        border: 0;
        padding: 0;
    }

    .InfoLine {
        background: #e6e9e2;

        td {
            padding: 5px 2px;
        }
    }
}

.Date {
    width: 120px;
    font-size: 12px;
}

.Select {
    width: 128px;
    font-size: 12px;
}
