// Colors

// Layout widths
$layout-width: 960px;
$sidebar-width: 24%;
$content-width: 76%;


// Colors
$green-main: #1C7D04;
$green-lighter: #1a7404;
$green-dark: #1d600d;
$tf-blue: #2189e6;
$grey-light: #d2d2d2;
$grey-dark: #222222;

// Background
$background-color: #e6e9e2;
$background-line: #eaece6;

// Header
$red-main: #f46f72;
$header-link: #333333;
$header-link-hover: #d692d6;
$header-administration-color: #FF0000;
$header-administration-purple: #993399;
$header-seperator: #cccccc;

// Conversations-inbox
$conversationsHover: #E6FFB8;
$converstaionsUnread: #ebebeb;
$converstaionsTrashed: #666;
