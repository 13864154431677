@import "src/styles/variables";

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.BlockedContainer {
    color: red;
    font-weight: bolder;
    font-size: 14px;
    //margin: 0 3px
}
.DeletedContainer {
    color: red;
}

.Container {
    width: 100%;
    min-width: 150px;
    position: relative;
    display: flex;
}

.ImageContainer {
    &.Blocked {
        border-bottom: 2px solid red !important;

        .ImageBackground {
            .Image {
                opacity: 0.5;
            }
        }
    }

    width: 66px;
    height: 66px;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    border: 2px solid #e6e9e2;
    padding: 1px;
    border-radius: 10px;
    position: relative;

    .ImageBackground {
        display: flex;
        width: 100%;
        height: 100%;
        border-radius: 10px;

        background: linear-gradient(-45deg, #ececec, #ccc, #ececec, #ccc);
        background-size: 400% 400%;
        animation: gradient 2s ease infinite;

        .Image {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }

    .ImageBig {
        width: 140px;
        height: 140px;
        top: -40px;
        left: -40px;
        position: absolute;
        border-radius: 10px;
        box-shadow: 0 0 5px 3px #ccc;
        z-index: 2;
    }
}

.ContainerInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 0 5px;
    width: 100%;

    .Name {
        display: flex;
        width: 100%;
        height: 20px;
        align-items: center;
    }

    .Statuses {
        width: 100%;
    }
}
