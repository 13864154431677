.gallery {
    .filters {
        input {
            margin-right: 5px;
        }
    }
    ul {
        list-style: none;
        float: left;
        width: 100%;
        padding-left: 0px;

        &:before {
            content: '';
            display: table
        }
    }

    .loader {
        clear: both;
    }
    
    li {
        float: left;
        width: 82px;
        height: auto;
        margin: 0 6px 0 0;
        padding-bottom: 14px;
        text-align: center;

        .photo {
            display: block;
            position: relative;
            border: 1px solid #B8B8B8;

            img {
                display: block;
                width: 80px;
                height: 106px;
                padding: 0px;
                background: #FFF;
            }

            .online {
                position: absolute;
                bottom: 0;
                right: 0;
                height: 15px;
                padding: 0 3px;
                background: #1C7D04;
                background: rgba(28,125,4,.7);
                color: #FFF;
                font-weight: 700;
                line-height: 15px;
            }
        }

        .details {
            width: 80px;
            padding: 0 3px;
            white-space: nowrap;

            h2 {
                font-weight: 400;
                font-size: 100%;
                margin-top:3px;
                margin-bottom: 2px;
                overflow: hidden;
            }

            .country {
                margin-top: 4px;
                font-size: 9px;
                height: 9px;
                width: 80px;
            }
        }
    }
}
