.female-phone-duplicates {
    display: inline-block;
}

a {
    color: #1a7204;
    text-decoration: none;
}

.fl, .l {
    float:left;
}

.fr, .r {
    float:right;
}

.cl {
    clear: both;
    height: 0px;
}

.content {
    position: relative;
}

.content:has(.deletedProfile){
    background-color: #DE9BA7;
}

.user-info-header {
    background-color: #2a811c;
    border-bottom: 1px solid grey;
    border-radius: 3px 3px 0 0;
    padding: 7px 5px 3px 10px;
    height: 37px;
    margin: -10px -10px 10px -10px;
    width: 720px;
    color: #fff;

    &.tm-header{
        background-image: linear-gradient(94deg, #00c4cc, #7529e8 100%);
    }

    &.tf-header{
        background-color: #2189e6;
    }

}

.little-logo {
    width: 30px;
    float: left;
    margin-top: 3px;

    &:hover {
        cursor: pointer;
    }
}

.reg-info {
    float: left;
}

.header-right-side {
    margin: 8px 10px 0 0;

    .link {
        cursor: pointer;
        color: #fff;
        background: none;
        border: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.newProfileUpdate {
    display: block;
    clear: both;
    width: 100%;
    color: white;
    padding: 6px 10px;
    margin: -9px 0px 4px -10px;;
    background: #ff6d00;
    text-align: center;

    .updateIcon {
        font-weight: bold;
        background-color: #fff;
        color: #ff6d00;
        padding: 1px 3px;
        border-radius: 12px;
    }
}

.reportsArea {
    position: relative;
    margin-bottom: 10px;
}

.photo-container {
    border:1px solid #ababab;
    padding: 2px 2px 0 2px;

    img {
        object-fit: cover;
    }

    .user-online {
        background-color: #1a7404;
        padding: 2px 5px;
        color: white;
        font-weight: bold;
        position: absolute;
        top: 2px;
    }
}

.app-photo {
    position:absolute;
    margin-top:90px;
    left:-160px;
}
.image-square img {
    width:150px;
    height:150px;
    border:1px solid #525252;
    padding:1px;
}

.app-photo-actions {
    font-size:18px;
    width: 150px;

    .editButton {
        width: 28px;
        margin-bottom: 3px;
    }
}

.messageButton {
    width: 100%;
    margin-top:5px;

    i {
        margin-right: 3px;
    }
}
.matchPref {
    padding:10px 0;
    color:#999;
}

.userMainPhoto {
    padding: 2px;
    display: block;
    float: left;
    border: 1px solid #ccc;
    width: 120px;
    margin: 10px 0px 0px 30px;

    .mainPhotoLabel {
        padding: 5px 0px 0px 12px;
        position: absolute;
        float: left;
        margin: 0px;
    }

    img {
        width: 120px;
    }
}

#user_information_block{
    margin-top: 20px;
    border-radius: 3px;
    border: 1px solid grey;
    display: block;
    float: left;
    width: 705px;
    color: #000;

    table td {
        border: none;
        padding: 2px;
    }
}

.wrap_text {
    background: #f2f4f0;
    padding: 3px;
    border: 1px solid grey;
    word-wrap: break-word;
    display: block;
    float: left;
    width: 560px;
    margin: 3px 0px;
    border-radius: 3px;
}

.paymentsBlock {
    i {
        color: #2a811c;
        font-size: 13px;
        margin-right: 2px;
    }
}

.paymentsHistory {
    font-size: 10px;
    span {
        display: inline-block;
    }
}

.duplicatedPhotos {
    float: left;
    border: 1px solid;
    padding: 2px 0 0 2px;

    .duplicate {
        float: left;
        margin-right: 2px;
        cursor: pointer;

        &.inactive {
            opacity: 0.6;
        }

        .userWithReports {
            position: absolute;
            color: #0045e8;
            font-size: 18px;
            margin: 2px;
        }

        .photo {
            width: 44px;
            height: 58px;
            border: 2px solid #1a7204;

            &.tm {
                border-color: #2e37c8;
            }
            &.tf {
                border-color: #2189e6;
            }
        }

        &.blocked {
            opacity: 0.6;

            .photo {
                border-color: red;
            }
        }
        &.duplicated {
            .photo {
                border-color: #ff6d00;
            }
        }
    }
}

.expandedDuplicate {
    width: 370px;
    top: 60px;
    left: -70px;
    border: 1px solid grey;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    z-index: 2;

    .closeButton {
        float: right;
        margin: -6px 10px 4px 0;
        font-size: 14px;
    }
}

.duplicate-header a{font-weight: bold; font-size: 14px; color: #fff; vertical-align: top; margin-left: 5px;}
.duplicate-header a .premium{ color: #EAA31A; margin-left: 3px;}
.duplicate-header a .verified{ color: #FFF; margin-left: 3px; font-size: 16px;}
.tg-duplicate-header {padding:5px; background-color: #2a811c;}
.tf-duplicate-header {padding:5px; background-color: #2189e6;}
.tm-duplicate-header {padding:5px; background-image: linear-gradient(94deg, #00c4cc, #7529e8 100%);}
.duplicateWrapper {
    position: relative;
    float: left;
}
.duplicateContent {
    display: flex;
    padding: 5px;

    img {
        padding: 1px;
        border: 1px solid grey;
    }

    .info {
        padding: 0 10px;
        max-width:265px;
    }
    .blockUser {
        padding: 10px 0;
    }
}

.orange {
    color: #ff6d00;
}

.userBlacklist {
    clear: both;
}

.user-name {
    font-family:Verdana;
    font-size:24px;
    font-weight:bold;
    display:block;
    float:left;
    margin:5px 0px 10px 0px;

    .userVerified {
        color: $green-lighter;
        font-size: 26px;
        margin-right: 5px;
    }
}
 #user_name_holder {
    padding-right:5px;
 }

.user-age {
    font-size:12px;
    font-weight:normal;
    margin-right: 10px;
}

.ageSelect {
    width: 55px;
    margin-right: 5px;
}

.name-edit {
    display:block;
    float:right;
    margin-top:16px;
    width:175px;

    i {
        font-size: 14px;
    }
}

.name-edit-input {
    padding:1px 5px;
    margin-right: 5px;
}

.edit_member_field {
    display: block;
}

.edit-icon {
    color: #555;
    font-size: 14px;
    float: right;
    cursor: pointer;

    &:hover {
        color: green;
    }
}

.actionSaveField {
    font-size: 14px;
    float: right;
    &:hover {
        text-decoration: none;
    }
}

.aboutMeEdit {
    font-size: 11px;
    font-family: Verdana;
    word-wrap: break-word;
    resize: none;
    width: 475px;
    min-height: 60px;
    padding: 5px;
    border-radius: 3px;
}

.profilePhoto {
    width: 186px;
    position: relative;
}

.publicInfo {
    width: 505px;
}

.verifyExample {
    object-fit: cover;
}

.online-status-wrapper {
    height:34px;
    background-color: #f2f4f0;
    font-size: 11px;
    margin-bottom: 10px;
}

.online-status {
    float:left;
    color: #929391;
    padding-top: 11px;
    padding-left: 10px;
}

.member_information_block {
    float: left;
    border-bottom: 1px solid #E6E6E6;
    display: block;
    width: 100%;
    padding: 4px 0px;

    table td {
        border: none;
        padding: 2px;
    }
}

.commentsBlock {
    float: left;
    width: 100%;
    margin-bottom: 10px;

    .commentsTextarea {
        overflow: hidden;
        word-wrap: break-word;
        resize: none;
        height: 60px;
        width: 100%;
    }
}

.member_information{color: #1c7d04; font-weight: normal;}

.member_column{width:150px;}

.locationContainer {
    height: 15px;
    float: left;
    overflow: hidden;
    pointer-events: none;

    &:hover {
        overflow: visible;
        height: auto;
    }
}

.other-countries {
    pointer-events: auto;
    color:grey;font-size:9px;
}

.visit_show_more {
    text-decoration: underline;
    cursor: pointer;
}

.trip-title {
    float: left;
    margin-left: 3px;
    margin-top: 2px;
}
.trip_icon {
    float: left;
    color: green;
    margin-right: 5px;
    margin-top: -3px;
    font-size: 16px;
}
.trip_info {
    width: 347px;
    float: right;
    height: 25px;
    margin-top: 2px;
}
.trip_country {
    width:185px;
    float:left;
}

.trip_country_name {
    width:170px;
    float:left;
}

.trip_date {
    color: #888;
    float:left;
}


// Right side

.verification_box {
    border: 1px solid #ababab;
    background: rgb(255,255,255);
    width: 180px;
    min-height: 80px;
    margin-bottom: 8px;
    padding: 2px;
    z-index: 2;
}

.verification_example {
    position: absolute;
    top: -24px;
    left: 5px;
    padding: 5px;
}

#verify_example {
    object-fit: cover;
}

.verification_actions {
    text-align:center;
    display:block;
    width:100%;

    i {
        font-size: 16px;
        margin: 2px 4px;
    }
}

.conversationSentBox {
    width: 690px;
    padding: 10px;
    background: #f2f4f0;
    border: 1px solid #000;
    z-index: 6;
}

.conversationSentBox .tableCell {
    padding: 0;
    border: none;
    padding-bottom: 5px;
}

.right-side {
    position: absolute;
    top: 235px;
    left: 735px;
}

.admin_tolls {
    border: 1px solid grey;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: rgba(255,255,255,0.5);
    width: 174px;
    min-height: 40px;
    padding: 5px;
}

.RefreshingUser {
    width: 125%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    background: #555;
    opacity: 0.5;
    z-index: 222;
}

.area-limited-access-switch {
    padding:5px 0;
}

.forbidden-to-whrite {
    color: #fff;
    background-color: red;
}

.action-limitedAccessChange {
    margin-right: 10px;
}

.action-limitedAnswerChange {
    margin-right: 10px;
}

.action-priority-change {
    cursor: pointer;
    i {
        font-size: 24px;
        color: #e6d602;
        text-shadow: 0px 0px 1px #704501;
    }
}

.action-priority-change-low {
    cursor: pointer;
    i {
        font-size: 16px;
        color: #7e3e14;
        text-shadow: 0px 0px 1px #704501;
    }
}

#priotites_box td {border-left:1px solid grey; width:50px; height:30px; text-align: center}
#priotites_box .first-td {border:0px solid grey; width: 40px}
#priotites_box .last-td {width:70px !important}

#automessages_list {
    background: #f2f4f0;
    text-align: left;

    ul {
        padding-left:20px
    }
    li {
        list-style-type:none;
    }
}

#user_update_holder {margin:15px 0px 0px 0px; float:left;}

.labelTg {
    color:#1c7d04;
    font-weight: bold;
    margin-right: 6px;
}
.labelTf {
    color:#2189e6;
    font-weight: bold;
}

.styledButton {
    font-family: Verdana, Arial, sans-serif;
    padding: 5px 10px;
    color: #fff;
    font-size: 12px;
    border:none;
    border-radius: 3px;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.button_update {background:#408806; margin-right: 8px; }
.button_skip {background:rgba(117,115,117,0.97); font-size: 11px; margin-top:15px;}
.buttonVerify {background: #FF6A00; font-weight: bold; border: 1px solid #444; color: #000; margin-right: 30px; width: 80px;}
.buttonBlock {background: #dedede; font-weight: bold; border: 1px solid #444; margin-bottom: 5px;}

#block_comment {
    width: 160px;
    height: 40px;
}

.expandedBlockArea {
    margin-top:5px;
}

.blockTable {
    width:400px; display:block; float:right;
}
.blockTable td {padding: 0px; border: none;}
.blockTable .w90{ width: 90px;}
.blockTable .w120{ width: 120px;}
.blockTable .w160{ width: 160px;}
.f14{ font-size: 14px;}
.f13{ font-size: 13px;}
.f11{ font-size: 11px;}
.f9{ font-size: 9px;}

.unwantedUsersName {
    width: 150px;
    line-height: 20px;
}

.replyRateWrapper {
    float: right;
    color: #333;
    padding: 11px 2px;
    width: 120px;
}

.replyRateBlock {
    display: block;
    float: right;
    width: 45px;
    margin: 3px 0px 3px 8px;
}

.rateTab {
    display: block;
    float: left;
    width: 3px;
    height: 9px;
    margin-right: 1px;
    background: #cfd5c8;
}
.rateActive {
    background: #197106;
}

// PHOTOS
.mainPhotoWarning {
    color: #FFF;
    padding: 4px;
    background: rgb(247,135,94);
    margin: 10px 0px 0px 0px;
    font-weight: bolder;
    border: 1px solid black;
    border-radius: 4px;
}

.photosList {
    h3 {
        font-size:11px;
    }

    .photo_view_block {width:82px; height: 153px; margin: 0 5px 10px 0; border: 1px solid $grey-light; position: relative;}
    .photo_view_functions_block {display: block; float: left}
    .orig_preview {position:absolute; top:1px; right:5px; padding:3px 3px 8px 8px; text-decoration:none; font-size:13px; color:#FFF; opacity:0.7; z-index: 4;}
    .orig_preview:hover {color:#939; text-decoration:none;}
    img { border: 1px solid #ccc; margin:0px 2px 0 0; cursor: pointer; object-fit: cover;}
    .private_photo {opacity: 0.4;}
    .newPhoto {border: 2px solid red;}
    .photoEditAction {
        display: block;
        float: left;
        margin: 0px;
        padding: 0px;
        width: 20px;
        height: 20px;
        font-size: 14px;
        color: #5a8350;
        cursor: pointer;
        i {
            margin-left: 2px;
        }
        &:hover {
            color: $green-main;
            font-size: 15px;
        }
    }
    .hidden-tf {
        color: red;
        &:hover {
            color: red;
        }
    }
}

