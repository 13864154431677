.MainInfoLine {
    border-bottom: 1px solid #E6E6E6;
    display: flex;
    flex-grow: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 4px 0;
}

.MainInfoLineTitle {
    width: 154px;
    flex-shrink: 0;
}

.MainInfoLineContent {
    width: 100%;
    max-width: 351px;
    color: #1c7d04;
}

.SexChangeButton {
    background-color: #408806;
    width: 80px;
    height: 35px;
    padding-left: 4px;
    border-radius: 5px;
    color: #fff;
    display: inline-flex;
    align-items: center;
}

.FemaleSexButton {
    background-color: #ef5555;
    margin-left: 30px;
}

.AboutMeText {
    word-wrap: break-word;
}
