.main-sidebar-left {
  width: $sidebar-width;
  float: left;
  margin-top: 1px;

  b {
    color: $header-link;
  }

  a {
    color: $green-lighter;
    text-decoration: none;
  }

  li:hover {
    text-decoration: underline;
  }

}