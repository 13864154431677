.verified-profiles {
    tr {
        border-bottom: 1px solid $grey-dark;
    }

    td {
        border: none;
        height: 106px;
        width: 80px;

        img {
            height: 100px;
            width: 70px;
        }

        i {
            cursor: pointer;
            font-size: 20px;
            color: $red-main;
        }
    }
}
