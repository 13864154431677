.about-me {
    &-form {
        background: #CCCCFF;
        padding: 10px;
    }

    mark {
        color: red;
        font-weight: 700;
    }

    table {
        width: 857px;
        background-color: #fff;;
    }

    td.male {
        color: blue;
    }

    td.female, td.blocked, td.hidden {
        color: red;
    }

    &-link {
        color: $green-main;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }

    .editName {
        padding: 1px 5px;
        width: 80px;
    }

    .aboutMePhoto {
        width: 100px;
        height: auto;
    }

    .translateLink {
        margin-top: 10px;
    }

    .translatedText {
        background: #eee;
        color: #444;
        margin-top:10px;
    }

}
