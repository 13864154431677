.Area {
    padding: 3px 0;
}

.Image {
    width: 50px;
    margin-right: 3px;
    padding: 1px;
    border: 1px solid #CCC;
}
