.unwanted-users {
    .group {
        display: inline-block;
    }

    input, label {
        display: block;
        margin: 5px 5px 5px 0;
    }
}
