button {
    border: none;
    background: none;
    color: #1a6e03;
}

.Header {
    display: flex;
    align-items: center;

    button {
        margin-left: auto;
        height: 25px;

        &:hover {
            text-decoration: underline;
        }
    }
}

.Table {
    width: 100%;
    border: 1px solid;
    border-collapse: collapse;
}

.AddForm {
    background: #e6e9e2;
    padding: 10px;
    border-radius: 10px;

    margin-bottom: 30px;
}
